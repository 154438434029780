import { Footer, useWorkflowContext } from "@components";
import { useSnackbarContext } from "@contexts/snackbar";
import { DownloadDocument, Events, OrderStatus } from "@helpers/enum";
import {
  useSaveProposal,
  useSendEmailNotification,
  useUpdateProposal,
} from "@hooks/mutations";
import { useGetSaveProposalPayload } from "@hooks/proposal";
import { useParams } from "react-router-dom";
import { ProposalFooterProps } from "./proposal-footer.props";
import { useFormContext } from "react-hook-form";
import { BrokerQuotationForm, TProposalForm } from "@_types/forms";
import { getDownloadDocumentPayload } from "@helpers/utils";
import {
  useCalculationsContext,
  useQuotationFormContext,
} from "@ntpkunity/controls-common";
import { useGetCompanyByTenantId } from "@hooks/queries";
import { isDealerRole } from "@helpers/utils";
import useGetAllLenders from "@hooks/queries/useGetAllLenders";
import useGetAllActiveDealers from "@hooks/queries/useGetAllDealers";
import { SNACKBAR_MESSAGES } from "@helpers/const";
import { useSetupsSelector } from "@hooks/useGetSetups";

const ProposalFooter = ({
  activeIndex,
  onNextButtonClick,
  onPrevButtonClick,
  handlePostSubmission,
  getNextButtonText,
}: ProposalFooterProps) => {
  const params = useParams<{ proposalId?: string }>();
  const isEditMode = params.proposalId;
  const { getValues, setValue, watch } = useFormContext<TProposalForm>();
  const { getPayload, validateProposalPayload } = useGetSaveProposalPayload(
    params.proposalId
  );
  const { mutateAsync: saveProposal } = useSaveProposal();
  const { mutateAsync: updateProposal } = useUpdateProposal();
  const { mutateAsync: sendProposalEmail } = useSendEmailNotification();
  const { data: companyInfo } = useGetCompanyByTenantId();
  const { data: calculations } = useCalculationsContext();
  const { setSnackbar } = useSnackbarContext();
  const {
    meta_data: { tabs },
  } = useWorkflowContext();
  const { getValues: getQuotationValues } =
    useQuotationFormContext<BrokerQuotationForm>();
  const setups = useSetupsSelector((state) => state);
  const { data: lenders } = useGetAllLenders();
  const { data: dealers } = useGetAllActiveDealers();

  const mutateProposal = isEditMode ? updateProposal : saveProposal;
  const lenderData = lenders?.find(
    (lender) => lender.name === watch("proposalDetails.lenderName")
  );
  const dealerData = dealers?.find(
    (dealer) => dealer.dealer_name === watch("proposalDetails.dealerName")
  );
  const getDocumentGenerationData = () => {
    const brokerSpecificValues = getValues();
    const quotationPayload = getQuotationValues();

    return getDownloadDocumentPayload({
      documentType: DownloadDocument.PROPOSAL,
      proposalValues: brokerSpecificValues,
      financialCalculations: calculations,
      financialValues: quotationPayload,
      setups: {
        financeTypes: setups.financeTypes.data,
        productTypes: setups.productTypes.data,
        clientTypes: setups.clientTypes.data,
        assetCategories: setups.assetCategories.data,
        assetConditions: setups.assetConditions.data,
        assetTypes: setups.assetTypes.data,
        assetSubTypes: setups.assetSubTypes.data,
        lenders: lenders,
      },
      companyId: companyInfo.id,
    });
  };

  const validateEmail = () => {
    if (!companyInfo.email) {
      throw new Error(SNACKBAR_MESSAGES.COMPANY_EMAIL_INVALID);
    }
    if (!lenderData.email) {
      throw new Error("Please provide a valid lender email");
    }
  };

  const handleEmailProposalToLender = () => {
    try {
      validateEmail();
      const downloadQuotationPayload = getDocumentGenerationData();
      const emailAttachments =
        downloadQuotationPayload.credit_documents?.length > 0
          ? downloadQuotationPayload.credit_documents.reduce((acc, doc) => {
              return [
                ...acc,
                ...(doc.attachments?.length > 0 ? doc.attachments : []),
              ];
            }, [])
          : [];
      sendProposalEmail({
        eventName: Events.EMAIL_PROPOSAL,
        payload: {
          source_email: companyInfo.email,
          bcc_emails: [],
          cc_emails: [],
          data: {
            ...downloadQuotationPayload,
            company_id: downloadQuotationPayload?.company_id,
            reference_number: "",
            customer_reference_id: "",
          },
          email_subject: `Proposal - ${downloadQuotationPayload.name}`,
          email: lenderData.email,
          email_attachment_keys: emailAttachments,
        },
      });
    } catch (err) {
      const errorMessage =
        err.message || SNACKBAR_MESSAGES.ERROR_WHILE_SENDING_EMAIL;
      setSnackbar({
        open: true,
        variant: "error",
        message: errorMessage,
      });
    }
  };
  const isFirstStep = activeIndex === 0;
  const isLastStep = activeIndex + 1 === tabs.length;
  const handleSubmitProposal = async () => {
    const missingMandatoryDocs = getValues().documents?.filter(
      (doc) => doc.isMandatory && doc.documents?.length == 0
    );
    if (missingMandatoryDocs?.length > 0) {
      setSnackbar({
        open: true,
        message: "Mandatory Documents are Missing",
        variant: "error",
      });
      return;
    }
    setSnackbar({ open: true, message: "Submitting Proposal" });
    const payload = getPayload(
      isDealerRole() ? OrderStatus.NEW : OrderStatus.SUBMITTED,
      lenderData,
      dealerData
    );

    if (!validateProposalPayload(payload)) return;

    try {
      const proposal = await mutateProposal(payload);
      setValue("identifier", proposal.identifier);
      setSnackbar(
        { open: true, message: "Proposal Submitted Successfully" },
        2000
      );
      handlePostSubmission();
      if (lenderData?.email) handleEmailProposalToLender();
    } catch (err) {
      setSnackbar({
        open: true,
        message: "There was an error while submitting proposal",
      });
    }
  };

  const handleNextButton = () =>
    isLastStep ? handleSubmitProposal() : onNextButtonClick();

  return (
    <Footer
      actionButtonText={getNextButtonText()}
      actionButtonOnClick={handleNextButton}
      showBackButton={!isFirstStep}
      backButtonOnClick={onPrevButtonClick}
    />
  );
};

export default ProposalFooter;
