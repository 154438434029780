import { UseQueryResult } from "react-query";
import {
  useGetAssetCategories,
  useGetAssetConditions,
  useGetAssetSubTypes,
  useGetAssetTypes,
  useGetClientTypes,
  useGetDealers,
  useGetDocumentTypes,
  useGetFinanceTypes,
  useGetProductTypes,
} from "./queries";
import {
  AssetCategoriesSetupRes,
  AssetConditionsSetupRes,
  AssetSubTypesSetupRes,
  AssetTypesSetupRes,
  ClientTypeSetupRes,
  DocumentTypeSetupRes,
  FinanceTypeSetup,
  FinanceTypeSetupRes,
  ProductTypeSetupRes,
} from "@_types/setups";
import { createContext, useContext } from "react";
import {
  getEntityTypeDealer,
  isDealerRole,
  removeDeletedEntities,
} from "@helpers/utils";
import useGetDealerProfile from "./queries/useGetDealerProfile";

type UseGetSetupsReturn = {
  financeTypes: UseQueryResult<FinanceTypeSetupRes>;
  productTypes: UseQueryResult<ProductTypeSetupRes>;
  clientTypes: UseQueryResult<ClientTypeSetupRes>;
  documentTypes: UseQueryResult<DocumentTypeSetupRes>;
  assetCategories: UseQueryResult<AssetCategoriesSetupRes>;
  assetTypes: UseQueryResult<AssetTypesSetupRes>;
  assetSubTypes: UseQueryResult<AssetSubTypesSetupRes>;
  assetConditions: UseQueryResult<AssetConditionsSetupRes>;
  getFilteredFinanceTypes: (financeType?: string) => {
    financeTypes: FinanceTypeSetup[];
    isError: boolean;
    isLoading: boolean;
  };
};

export const SetupsContext = createContext<UseGetSetupsReturn | null>(null);
export const SetupsContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const queries = useGetSetups();
  return (
    <SetupsContext.Provider value={queries}>{children}</SetupsContext.Provider>
  );
};

export const useSetupsSelector = <T,>(
  selector: (state: UseGetSetupsReturn) => T
): T => {
  const context = useContext(SetupsContext);

  if (!context) {
    throw new Error(
      "useSetupsSelector must be used within a SetupsContextProvider"
    );
  }

  return selector(context);
};

export const useGetSetups = (): UseGetSetupsReturn => {
  const financeTypesQuery = useGetFinanceTypes();
  const productTypesQuery = useGetProductTypes();
  const clientTypesQuery = useGetClientTypes();
  const documentTypesQuery = useGetDocumentTypes();
  const assetCategoriesQuery = useGetAssetCategories();
  const assetSubTypesQuery = useGetAssetSubTypes();
  const assetConditionsQuery = useGetAssetConditions();
  const assetTypeQuery = useGetAssetTypes();

  // Filtering finance types for dealer
  const _isDealerRole = isDealerRole();
  const {
    data: dealerAssociation,
    isLoading: dealerAssociationLoading,
    isError: dealerAssociationError,
  } = useGetDealers(getEntityTypeDealer());
  const dealerCode = _isDealerRole && dealerAssociation?.[0]?.dealer_code;
  const {
    isLoading: dealerProfileLoading,
    isError: isDealerProfileError,
    data: dealerProfile,
  } = useGetDealerProfile(dealerCode);

  const availableFinanceTypes = dealerProfile?.available_finance_types || [];

  const getFilteredFinanceTypes = (financeType?: string) => {
    const financeTypes = removeDeletedEntities(
      financeType,
      financeTypesQuery.data
    );
    const filteredFinanceTypes = !_isDealerRole
      ? financeTypes
      : financeTypes?.filter(
          (type) =>
            type.code === financeType ||
            availableFinanceTypes.includes(type.name.trim())
        );
    const isError =
      financeTypesQuery.isError ||
      (_isDealerRole && (isDealerProfileError || dealerAssociationError));
    const isLoading =
      financeTypesQuery.isLoading ||
      (_isDealerRole && (dealerProfileLoading || dealerAssociationLoading));

    return {
      isLoading,
      isError,
      financeTypes: filteredFinanceTypes,
    };
  };

  return {
    financeTypes: financeTypesQuery,
    productTypes: productTypesQuery,
    clientTypes: clientTypesQuery,
    documentTypes: documentTypesQuery,
    assetCategories: assetCategoriesQuery,
    assetTypes: assetTypeQuery,
    assetSubTypes: assetSubTypesQuery,
    assetConditions: assetConditionsQuery,
    getFilteredFinanceTypes,
  };
};
